.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.title {
  font-size: 44px;
  margin-bottom: 32px;
}

.walletDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.Game {
  margin: 20px;
  display: flex;
  justify-content: center;
}

button {
  background-color: #5689db;
  border: 1px solid #5689db;
  border-radius: 3px;
  display: inline-block;
  color: #fff;
  font-size: 12pt;
  padding: 7px 28px;
  text-decoration: none;
  margin: 5px;
  transition: all 0.5s;
  max-width: 160px;
  min-width: 160px;
}

button:hover {
  background-color: lightblue;
  font-size: 14pt;
}

.Board {
  display: flex;
}

.Slat {
}

.Hole {
  padding: 5px;
  margin: 1px;
  min-width: 30px;
  min-height: 30px;
  border: 1px solid lightblue;
}

.Hole div {
  position: relative;
  min-width: 30px;
  min-height: 30px;
  border-radius: 4px;
  top: -400px;
  transition: transform 0.5s;
}

.Red {
  background-color: #ff456c;
  transform: translateY(400px);
}

.Blue {
  background-color: #2cc5ff;
  transform: translateY(400px);
}

.winnerMessage {
  margin: 20px;
  font-size: 0pt;
  font: 12pt;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.18, 0.99, 0.8, 1.17);
}

.appear {
  opacity: 1;
  font-size: 20pt;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
